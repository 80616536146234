import Auth from "components/auth/Auth";
import checkCanSkipToBlackCheckout from "components/black/checkCanSkipToBlackCheckout";
import Fundamentei from "components/branding/Fundamentei";
import { FundamenteiBlackIcon } from "components/branding/FundamenteiBlack";
import ProfileDropdown from "components/core/ProfileDropdown";
import Notifications from "components/engagement/notifications/Notifications";
import DarkModeButton from "components/financials/modern/DarkModeButton";
import LanguageSelector from "components/navbar/LanguageSelector";
import MobileNavbarNav from "components/navbar/MobileNavbarNav";
import NavbarLink from "components/navbar/NavbarLink";
import PlanOverrideSwitcher from "components/navbar/PlanOverrideSwitcher";
import NavbarSearchInput, {
  getIssuerURLPrefix,
  getLinkToIssuerOverview,
  getLinkToIssuerValuation,
} from "components/search/NavbarSearchInput";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuItemLink,
  NavigationMenuList,
  NavigationMenuListItemLink,
  NavigationMenuTrigger,
  NavigationMenuUnorderedList,
} from "components/ui/NavigationMenu";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";
import classNames from "components/ui/classNames";
import useTheme from "components/ui/useTheme";
import isDarkModeSupportedPage from "components/ux/isDarkModeSupportedPage";
import { useAmplitude } from "lib/amplitude/Amplitude";
import useSelfie from "lib/hooks/useSelfie";
import { MenuIcon, SettingsIcon, XIcon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useEventListener, useScrollLock } from "usehooks-ts";

interface NavbarProps {
  hardRefresh?: boolean;
  disableStickyNavbar?: boolean;
}

export default function Navbar({ hardRefresh, disableStickyNavbar = false }: NavbarProps) {
  const viewer = useSelfie();
  const router = useRouter();
  const amplitude = useAmplitude();
  const theme = useTheme();
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const isValuationContext = router.pathname.endsWith("/valuation");
  const isDark = (viewer && viewer.isBlack) || theme === "dark";

  const logoAnchor = (
    <a href="/" title="Página inicial">
      <div className="flex items-center">
        <Fundamentei fill={isDark ? "white" : undefined} width={30} height={32} />
        {viewer?.isBlack && <FundamenteiBlackIcon width={100} height={100} className="hidden md:inline-flex" />}
      </div>
    </a>
  );
  useEventListener("resize", (event: any) => {
    if (isHamburgerMenuOpen && event.target.innerWidth > 1024) {
      setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    }
  });

  useScrollLock({
    autoLock: isHamburgerMenuOpen,
    lockTarget: "#root",
  });
  const variant = isDark ? "black" : "default";

  return (
    <div
      className={classNames(
        "relative left-0 top-0 z-20",
        "flex justify-center",
        "mx-auto mt-0 w-full border-b border-neutral-200 bg-white shadow-sm lg:px-8 lg:py-2 xl:px-0",
        {
          sticky: !disableStickyNavbar,
          "fixed bottom-0": isHamburgerMenuOpen,
        },
        {
          "dark border-neutral-700 bg-slate-950": isDark,
        },
      )}
    >
      <div className={classNames("sticky top-0 flex w-full max-w-7xl flex-col")}>
        <nav
          className={classNames(
            "relative flex h-16 min-h-16 items-center bg-white pl-4 pr-2 font-aeonik",
            "lg:border-transparent lg:bg-transparent lg:pl-0",
            "border-b border-transparent",
            {
              "shadow-md dark:border-slate-800 dark:shadow-md dark:shadow-black/80": isHamburgerMenuOpen,
              "bg-slate-950": isDark,
            },
          )}
        >
          <div className="flex w-full items-center">
            {hardRefresh ? (
              logoAnchor
            ) : (
              <Link
                prefetch={false}
                href={{
                  pathname: "/",
                  query: router?.query,
                }}
                passHref={true}
                legacyBehavior={true}
              >
                {logoAnchor}
              </Link>
            )}

            <div className="ml-4 mr-3 w-full lg:ml-6 lg:mr-6 lg:max-w-xs">
              <NavbarSearchInput
                onChange={(hit) => {
                  // Helps testing by interpolating the URL to change the ticker symbol
                  const { pathname } = router;
                  const isSameAssetClass =
                    (pathname.startsWith("/us") && (hit.type === "AMERICAN_COMPANY" || hit.type === "AMERICAN_REIT")) ||
                    (pathname.startsWith("/br") && hit.type === "BRAZILIAN_COMPANY") ||
                    (pathname.startsWith("/fiis") && hit.type === "BRAZILIAN_REAL_ESTATE_FUND");

                  // Keep the user in the same page if he's navigating between the same "issuer class"
                  if (isSameAssetClass && hit.tickerSymbols.length > 0) {
                    const resolvedTickerSymbol = hit.tickerSymbols[0].toLowerCase();
                    const prefix = getIssuerURLPrefix(hit);
                    const tail = pathname.substring(pathname.indexOf("[tickerSymbol]"));
                    const as = tail.replace("[tickerSymbol]", resolvedTickerSymbol);
                    router.push(`/${prefix}/${tail}`, `/${prefix}/${as}`);
                    return;
                  }

                  if (isValuationContext) {
                    const { href, as } = getLinkToIssuerValuation(hit);
                    router.push(href, as);
                    return;
                  }
                  const { href, as } = getLinkToIssuerOverview(hit);
                  router.push(href, as);
                }}
              />
            </div>

            <div className="hidden h-8 items-center space-x-6 lg:flex">
              {viewer ? (
                <NavigationMenu>
                  <NavigationMenuList>
                    <NavigationMenuItem>
                      <NavigationMenuTrigger variant={variant}>
                        <FormattedMessage
                          id="6KdNOV"
                          defaultMessage="Ativos"
                          description="Item da barra de navegação"
                        />
                      </NavigationMenuTrigger>

                      <NavigationMenuContent>
                        <NavigationMenuUnorderedList>
                          <NavigationMenuListItemLink href="/portfolio">
                            <FormattedMessage
                              id="gm0um9"
                              defaultMessage="Minha Carteira"
                              description="Item da barra de navegação"
                            />
                          </NavigationMenuListItemLink>

                          <NavigationMenuListItemLink href="/bookmarks">
                            <FormattedMessage
                              id="MosWbU"
                              defaultMessage="Meus Favoritos"
                              description="Item da barra de navegação"
                            />
                          </NavigationMenuListItemLink>

                          {viewer.isPremium && (
                            <NavigationMenuListItemLink href="/compare">
                              <FormattedMessage
                                id="3cwfdx"
                                defaultMessage="Comparativo"
                                description="Item da barra de navegação"
                              />
                            </NavigationMenuListItemLink>
                          )}

                          <NavigationMenuListItemLink href="/financials">
                            <FormattedMessage
                              id="MmCdky"
                              defaultMessage="Balanços"
                              description="Item da barra de navegação"
                            />
                          </NavigationMenuListItemLink>
                        </NavigationMenuUnorderedList>
                      </NavigationMenuContent>
                    </NavigationMenuItem>

                    <NavigationMenuItem>
                      <NavigationMenuTrigger variant={variant}>
                        <FormattedMessage
                          id="MvR5bM"
                          defaultMessage="Explorar"
                          description="Item da barra de navegação"
                        />
                      </NavigationMenuTrigger>
                      <NavigationMenuContent>
                        <NavigationMenuUnorderedList>
                          <NavigationMenuListItemLink href="/events-calendar">
                            <FormattedMessage
                              defaultMessage="Agenda"
                              id="gBKUs2"
                              description="Item da barra de navegação"
                            />
                          </NavigationMenuListItemLink>

                          <NavigationMenuListItemLink href="/discussions">
                            <FormattedMessage
                              defaultMessage="Discussões"
                              id="QtRrEY"
                              description="Item da barra de navegação"
                            />
                          </NavigationMenuListItemLink>

                          <NavigationMenuListItemLink href="/fatos-relevantes">
                            <FormattedMessage
                              defaultMessage="Fatos Relevantes"
                              id="ietIM6"
                              description="Item da barra de navegação"
                            />
                          </NavigationMenuListItemLink>
                        </NavigationMenuUnorderedList>
                      </NavigationMenuContent>
                    </NavigationMenuItem>

                    <NavigationMenuItemLink href="/ranking" variant={variant}>
                      <FormattedMessage defaultMessage="Ranking" id="YJ60t1" />
                    </NavigationMenuItemLink>

                    {viewer && checkCanSkipToBlackCheckout(viewer) && (
                      <NavigationMenuItemLink
                        href="https://black.fundamentei.com/black?hsrc=NAVBAR"
                        onClick={() => {
                          amplitude.logEvent("Click on link to become Black", {
                            origin: "Seja Black - Navbar",
                          });
                        }}
                        className={classNames(
                          "inline-flex w-full rounded-full bg-stone-100 px-4 py-2 font-bold text-stone-900",
                          "ring-1 ring-stone-300 ring-offset-2",
                          "hover:border-stone-300 hover:bg-stone-900 hover:text-white",
                          "dark:bg-neutral-600/30 dark:text-orange-200 dark:ring-orange-950/20 dark:ring-offset-0 dark:hover:opacity-80",
                        )}
                      >
                        Seja Black
                      </NavigationMenuItemLink>
                    )}

                    {viewer && !viewer.isPremium && !checkCanSkipToBlackCheckout(viewer) && (
                      <NavigationMenuItemLink
                        href="/premium"
                        className={classNames(
                          "inline-flex w-full rounded-full bg-blue-50 px-4 py-2 font-bold text-blue-600",
                          "ring-1 ring-blue-100 ring-offset-2",
                          "hover:border-blue-300 hover:bg-blue-600 hover:text-white",
                          "dark:bg-teal-600/30 dark:text-teal-300 dark:ring-teal-950/20 dark:ring-offset-0 dark:hover:opacity-80",
                        )}
                      >
                        <FormattedMessage defaultMessage="Seja Premium" id="mEL1HF" />
                      </NavigationMenuItemLink>
                    )}

                    {viewer && (viewer.isSiteAdmin || viewer.isBlack) && (
                      <NavigationMenuItem>
                        <NavigationMenuTrigger variant={variant}>Black</NavigationMenuTrigger>
                        <NavigationMenuContent>
                          <NavigationMenuUnorderedList>
                            <NavigationMenuListItemLink href="/black/courses">
                              <FormattedMessage
                                defaultMessage="Cursos"
                                id="4Tj6qz"
                                description="Item da barra de navegação"
                              />
                            </NavigationMenuListItemLink>

                            <NavigationMenuListItemLink href="/black/forum" exact={true}>
                              <FormattedMessage
                                defaultMessage="Fórum"
                                id="XGpedD"
                                description="Item da barra de navegação"
                              />
                            </NavigationMenuListItemLink>
                          </NavigationMenuUnorderedList>
                        </NavigationMenuContent>
                      </NavigationMenuItem>
                    )}
                  </NavigationMenuList>
                </NavigationMenu>
              ) : (
                <>
                  <NavbarLink prefetch={false} href="/premium" className="text-brand">
                    <FormattedMessage defaultMessage="Premium" id="vVeWQk" description="Item da barra de navegação" />
                  </NavbarLink>

                  <NavbarLink prefetch={false} href="/financials">
                    <FormattedMessage id="MmCdky" defaultMessage="Balanços" description="Item da barra de navegação" />
                  </NavbarLink>

                  <NavbarLink prefetch={false} href="/fatos-relevantes">
                    <FormattedMessage
                      defaultMessage="Fatos Relevantes"
                      id="ietIM6"
                      description="Item da barra de navegação"
                    />
                  </NavbarLink>
                  <NavbarLink prefetch={false} href="/events-calendar">
                    <FormattedMessage defaultMessage="Agenda" id="gBKUs2" description="Item da barra de navegação" />
                  </NavbarLink>
                </>
              )}
            </div>

            {viewer ? (
              <>
                <div className="ml-auto hidden items-center justify-end space-x-3 lg:flex">
                  <LanguageSelector />
                  {isDarkModeSupportedPage(router.pathname) && <DarkModeButton persistency="userSettings" />}
                  <Notifications viewer={viewer} />
                  <ProfileDropdown viewer={viewer} />
                </div>

                <div className="ml-auto flex items-center justify-end space-x-3 lg:hidden">
                  <SettingsPopover />

                  <Notifications viewer={viewer} />
                </div>
              </>
            ) : (
              <div className="ml-auto hidden items-center justify-end space-x-4 lg:flex">
                <NavbarLink prefetch={false} href="/login">
                  <FormattedMessage defaultMessage="Login" id="hLnzGA" description="Item da barra de navegação" />
                </NavbarLink>

                <LanguageSelector />
                {isDarkModeSupportedPage(router.pathname) && <DarkModeButton persistency="queryString" />}

                {process.env.FUNDAMENTEI_ENV === "development" && (
                  <Auth>{({ token }) => (token ? <PlanOverrideSwitcher /> : null)}</Auth>
                )}
              </div>
            )}

            <div className="flex items-center pl-3 lg:hidden">
              <button
                type="button"
                onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
                className={classNames(
                  "rounded-xl border border-slate-200 bg-slate-50/60 p-2 text-slate-600",
                  "dark:border-slate-950 dark:bg-slate-950 dark:text-slate-300 dark:hover:text-white",
                  "focus-visible:outline-none",
                  "focus-visible:ring-1 focus-visible:ring-offset-1",
                  "focus-visible:ring-gray-100 focus-visible:ring-offset-gray-300",
                  "focus-visible:border-transparent focus-visible:bg-gray-50 focus-visible:text-gray-600",
                  "dark:focus-visible:ring-slate-950 dark:focus-visible:ring-offset-transparent",
                  {
                    "dark:bg-violet-600/20 dark:text-violet-200": isHamburgerMenuOpen,
                  },
                )}
              >
                <span className="sr-only">Abrir Menu</span>
                {isHamburgerMenuOpen ? (
                  <XIcon strokeWidth={2} className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon strokeWidth={2} className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </nav>

        <aside
          className={classNames("overflow-y-scroll", {
            hidden: !isHamburgerMenuOpen,
          })}
        >
          <MobileNavbarNav isDark={isDark} />
        </aside>
      </div>
    </div>
  );
}

function SettingsPopover() {
  const router = useRouter();
  const viewer = useSelfie();
  const theme = useTheme();

  const isDark = (viewer && viewer.isBlack) || theme === "dark";

  return (
    <Popover>
      <PopoverTrigger
        className={classNames(
          "flex h-10 w-10 items-center justify-center rounded-xl",
          "bg-neutral-100 text-gray-700 hover:text-gray-500",
          "border border-transparent",
          "dark:bg-slate-800 dark:text-slate-200 dark:hover:bg-slate-700 dark:hover:text-gray-300",
        )}
      >
        <SettingsIcon />
      </PopoverTrigger>

      <PopoverContent
        className={classNames("flex items-center space-x-2 p-2 dark:shadow-neutral-950/40", {
          "border-slate-800 bg-slate-800 shadow-neutral-950/40": isDark,
        })}
        align="center"
        onOpenAutoFocus={(event) => {
          // See: https://github.com/radix-ui/primitives/discussions/935
          event.preventDefault();
        }}
      >
        <LanguageSelector />
        {isDarkModeSupportedPage(router.pathname) && (
          <DarkModeButton persistency={viewer ? "userSettings" : "queryString"} />
        )}
      </PopoverContent>
    </Popover>
  );
}
